// @flow
import * as React from 'react';
import Image from '../Image';

import { H1, BannerContainer, TextWrapper, ImageWrapper } from './styles';

type Props = {
  children?: React.Node,
  workplace?: boolean,
  noShadow: boolean,
  pageName?: ?string,
  img?: {
    url: string,
    file?: { url: string },
  },
  userLocation: {},
  sessionID: string,
};

const Banner = ({ pageName, img, workplace, children }: Props) => (
  <BannerContainer>
    <ImageWrapper workplace={workplace}>
      {(img?.url || img?.file?.url) && (
        <Image
          src={img.url || `https://${img.file?.url || ''}`}
          alt="Home page banner"
          quality={100}
        />
      )}
    </ImageWrapper>

    <TextWrapper>
      {pageName && <H1>{pageName}</H1>}
      {children && children}
    </TextWrapper>
  </BannerContainer>
);

Banner.defaultProps = {
  pageName: null,
  img: null,
  children: null,
  workplace: false,
};
export default Banner;
