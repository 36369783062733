import { remCalc } from '../lib/styleMethods';
import styled from 'styled-components';
import { COLORS, DESKTOP } from '../lib/constants';

export const SelectContainer = styled.div({
  height: 40,
  width: '90%',
  maxWidth: remCalc(300),
  overflow: 'hidden',
  position: 'relative',
  '&:after': {
    content: '"▼"',
    position: 'absolute',
    top: 0,
    right: 8,
    pointerEvents: 'none',
    padding: '17px 0',
    fontSize: 8,
  },
});

export const Select = styled.select({
  width: '100%',
  border: '1px solid rgb(230, 230, 230)',
  background: COLORS.white,
  padding: remCalc(10),
  fontSize: '1rem',
  appearance: 'none',
});

export const Label = styled.label({
  display: 'block',
  padding: `${remCalc(10)} 0`,
});

export const PlanSelectContainer = styled.div({
  display: 'inline-block',
  width: '100%',
  [DESKTOP]: {
    width: '50%',
  },
});

export const PlanContainer = styled.div({
  padding: `${remCalc(20)} 0`,
  borderBottom: `1px solid ${COLORS.black007}`,
});
