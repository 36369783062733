// @flow
/* eslint-disable react/no-danger */
import React from 'react';
import * as c from '../lib/constants';

import icons from './icons';

type Props = {
  icon: string,
};

const Icons = ({ icon, ...rest }: Props) => (
  <svg {...rest} dangerouslySetInnerHTML={{ __html: icons[icon] }} />
);

export const Arrow = (props: any) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.274 8.48l1.452 1.375 4.615-4.87L1.73.11.274 1.488l3.312 3.496"
      fill={`${c.COLORS.black004}`}
    />
  </svg>
);

export const Play = (props: any) => (
  <svg {...props}>
    <path
      d="M.274 8.48l1.452 1.375 4.615-4.87L1.73.11.274 1.488l3.312 3.496"
      fill={`${c.COLORS.black004}`}
    />
  </svg>
);

export const LinkChain = (props: any) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.37 3.674L8.542 5.5a2.142 2.142 0 0 1-1.819.602l1.21-1.21L9.76 3.065A1.292 1.292 0 0 0 7.935 1.24L6.109 3.065l-1.211 1.21A2.142 2.142 0 0 1 5.5 2.458L7.326.63a2.15 2.15 0 1 1 3.043 3.043zM6.716 4.892L4.891 6.718l-.609-.61L6.11 4.284l.608.609zM1.24 7.935A1.291 1.291 0 0 0 3.065 9.76l1.826-1.826 1.21-1.21A2.141 2.141 0 0 1 5.5 8.542L3.674 10.37A2.151 2.151 0 1 1 .63 7.326L2.457 5.5a2.141 2.141 0 0 1 1.819-.602l-1.21 1.21-1.827 1.827z"
      fill="#221f1f"
      fillRule="evenodd"
    />
  </svg>
);

export const Close = (props: any) => (
  <svg {...props}>
    <path
      fill="#eee"
      fillRule="evenodd"
      d="M9.773 7.65l5.53-5.53C14.61 1.477 13.843.628 13.18 0L7.65 5.53 2.12 0C1.46.627.695 1.476 0 2.12l5.53 5.53L0 13.18c.604.696 1.427 1.456 2.12 2.123l5.53-5.53 5.53 5.53c.696-.667 1.518-1.427 2.123-2.122l-5.53-5.53z"
    />
  </svg>
);

export const NavArrow = (props: any) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0l6 4.5L0 9z" fill={`${c.COLORS.black004}`} />
  </svg>
);

export default Icons;
