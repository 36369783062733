import styled from 'styled-components';
import { remCalc } from '../lib/styleMethods';

export const COLORS = {
  nfBlack: '#221f1f',
  nfRed: '#e50914',
  nfTan: '#f5f5f1',
  nfRedHover: '#ff373d',
  nfRedActive: '#cd070e',

  black001: '#221F1F',
  black002: '#424040',
  black003: '#696868',
  black004: '#8c8c8c',
  black005: '#adadad',
  black006: '#cfcfcf',
  black007: '#e6e6e6',
  black008: '#f5f5f5',
  white: '#ffffff',
  mobileNav: '#F8F8F8',
};

export const CalculatorContainer = styled.section({
  background: COLORS.black008,
  width: '100%',
  padding: `0 ${remCalc(30)} ${remCalc(25)}`,
});

export const TotalContainer = styled.div({
  display: 'grid',
  grid: 'auto-flow / auto auto',
  padding: `${remCalc(20)} 0`,
});

export const Header = styled.div({
  fontWeight: 'bold',
  fontSize: '20px',
  marginTop: '10px',
  marginBottom: '20px',
});

export const Description = styled.div({
  fontSize: '18px',
  marginTop: '10px',
  marginBottom: '20px',
});

export const H4 = styled.h4({
  padding: `${remCalc(10)} 0`,
  display: 'inline-block',
});

export const H3 = styled.h3({
  padding: `0 ${remCalc(10)}`,
  display: 'inline-block',
  alignSelf: 'center',
  justifySelf: 'end',
});
