import styled from 'styled-components';
import { remCalc } from '../lib/styleMethods';
import TypeScale from '../lib/type';

import {
  COLORS,
  ANIMATION_DURATION_SHORT,
  DEFAULT_ANIMATION_TIMING,
} from '../lib/constants';

export const Box = styled.div`
  width: 95%;
  border-top: inherit;
  position: absolute;
  top: calc(100% + 1px);
  background-color: ${COLORS.white};
  z-index: 1;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
`;

export const InnerBox = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;

  background-color: inherit;
`;

export const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: -20%;
  z-index: -1;

  :after {
    content: '';
    position: absolute;
    -webkit-box-shadow: 0 0 35px ${COLORS.black001};
    box-shadow: 0 0 35px ${COLORS.black001};
    top: calc(100% - 65px);
    left: 5%;
    right: 5%;
    width: 90%;
    height: 55px;
    -moz-border-radius: 100%;
    border-radius: 100%;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  z-index: 1;
`;

export const ItemPadding = styled.div`
  height: ${remCalc(15)};
  background-color: ${COLORS.white};
  position: relative;
`;

export const Item = styled.li`
  cursor: pointer;
  height: auto;
  text-align: left;
  line-height: 1em;
  font-size: 1rem;
  font-weight: 400;
  box-shadow: none;
  padding: ${remCalc(15)} ${remCalc(19)};
  color: ${({ isSelected }) =>
    isSelected ? COLORS.nfRedActive : COLORS.black001};
  background-color: ${({ isActive }) =>
    isActive ? COLORS.black008 : 'inherit'};
`;

export const Button = styled.button({
  position: 'relative',
  width: '95%',
  padding: `${remCalc(6)} 0`,
  textAlign: 'left',
  lineHeight: '40px',
  border: `1px solid ${COLORS.black007}`,
  backgroundColor: COLORS.white,
  transition: `all ${ANIMATION_DURATION_SHORT} ${DEFAULT_ANIMATION_TIMING}`,
  '& svg': {
    position: 'absolute',
    right: '15px',
    top: 'calc(50% - 3px)',
    width: '12px',
    height: '10px',
  },

  '&:focus': {
    outline: 'none',
  },

  '&:disabled': {
    cursor: 'no-drop',
  },
});

export const P = styled(TypeScale.P)`
  font-size: 1rem;
  color: ${({ selected }) => (selected ? COLORS.black001 : COLORS.black004)};
  padding-left: ${remCalc(19)};
  padding-right: ${remCalc(28)};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: ${remCalc(20)};
`;
export const DropdownContainer = styled.div({
  position: 'relative',
  width: '100%',
});

export const Label = styled.label({
  display: 'block',
  lineHeight: '22px',
  fontWeight: 300,
  position: 'relative',
  marginBottom: '10px',
});
