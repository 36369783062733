import React, { Component } from 'react';
import './App.scss';
import Banner from './components/Banner';
import BenefitsCostCalculator from './components/BenefitsCostCalculator';
import Logo from './components/Logo';
import { Header, HeaderContainer } from './components/Logo/styles';
import Global from './components/lib/styles';

export default class App extends Component {
  state = {
    loading: true,
    plans: {},
    yearsOfPlanData: [],
    title: '',
  };

  async componentWillMount() {
    const contentUrl = `${!process.browser ? 'http://localhost:7101' : ''}/api/content`;
    const eventsRes = await fetch(contentUrl, {
      method: 'GET',
      mode: 'cors',
    });
    const res = await eventsRes.json();
    let { title, banner } = res;
    const url = banner?.fields?.file?.url || '';
    this.setState({ yearsOfPlanData: res.yearsOfPlanData, url, title });
  }

  render() {
    if (this.state.yearsOfPlanData?.length > 0) {
      return (
        <div>
          <Global />
          <HeaderContainer>
            <Header>
              <Logo />
            </Header>
          </HeaderContainer>
          <div>
            <Banner img={{ url: this.state.url }} pageName={this.state.title} />
            <main>
              {this.state.yearsOfPlanData.map((year, index) => {
                return <BenefitsCostCalculator key={year.title} {...year} />;
              })}
            </main>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}
