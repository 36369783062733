import React from 'react';
import Downshift from 'downshift';
import {
  Box,
  Button,
  DropdownContainer,
  InnerBox,
  Item,
  ItemPadding,
  List,
  P,
  Shadow,
} from './style';
import Icon from '../Icons';
import type { SelectProps } from './index';

interface DropdownProps extends SelectProps {
  selectedItem: string;
  onChange: () => void;
  stateReducer: () => void;
  truncate: () => string;
  selectedItems: Array<string>;
  scope: {};
  setButton: () => void;
}
const Dropdown = ({
  options,
  selectedItem: propSelectedItem,
  onChange,
  stateReducer,
  truncate,
  placeholder,
  selectedItems,
  scope,
  setButton,
  dark,
  disabled,
}: DropdownProps) => (
  <Downshift
    selectedItem={propSelectedItem}
    onSelect={onChange}
    stateReducer={stateReducer}
    scope={scope}
  >
    {({
      isOpen,
      getToggleButtonProps,
      getItemProps,
      highlightedIndex,
      selectedItem,
      getRootProps,
    }) => (
      <DropdownContainer {...getRootProps({}, { suppressRefError: true })}>
        <Button
          {...getToggleButtonProps()}
          ref={btn => {
            setButton(btn);
          }}
          dark={dark}
          disabled={disabled}
        >
          <P selected={selectedItem} dark={dark}>
            {(selectedItem && truncate(selectedItem || '')) || placeholder}
          </P>
          <Icon icon="arrowDown" />
        </Button>
        <div>
          {isOpen && (
            <Box dark={dark}>
              <InnerBox>
                <ItemPadding dark={dark} />
                <List>
                  {options.map((item, index) => (
                    <Item
                      {...getItemProps({
                        item,
                        isActive: highlightedIndex === index,
                        isSelected:
                          selectedItems.length > 0
                            ? selectedItems.includes(item)
                            : false,
                      })}
                      key={item}
                      dark={dark}
                    >
                      <div>{item}</div>
                    </Item>
                  ))}
                </List>
                <ItemPadding dark={dark} />
              </InnerBox>
              <Shadow dark={dark} />
            </Box>
          )}
        </div>
      </DropdownContainer>
    )}
  </Downshift>
);

export default Dropdown;
