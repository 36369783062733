import { createGlobalStyle } from 'styled-components';
import { remCalc } from './styleMethods';
import * as c from './constants';

/* eslint-disable no-unused-expressions */
const Global = createGlobalStyle`
  @font-face {
    font-family: 'NetflixSans';
    src: url('/fonts/NetflixSans_W_Bd.woff');
    src: url('/fonts/NetflixSans_W_Bd.eot');
    src: url('/fonts/NetflixSans_W_Bd.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/NetflixSans_W_Bd.woff2') format('woff2'),
      url('/fonts/NetflixSans_W_Bd.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'NetflixSans';
    src: url('/fonts/NetflixSans_W_Md.woff');
    src: url('/fonts/NetflixSans_W_Md.eot');
    src: url('/fonts/NetflixSans_W_Md.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/NetflixSans_W_Md.woff2') format('woff2'),
      url('/fonts/NetflixSans_W_Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
  }

  @font-face {
    font-family: 'NetflixSans';
    src: url('/fonts/NetflixSans_W_Lt.woff');
    src: url('/fonts/NetflixSans_W_Lt.eot');
    src: url('/fonts/NetflixSans_W_Lt.eot?#iefix')
        format('embedded-opentype'),
      url('/fonts/NetflixSans_W_Lt.woff2') format('woff2'),
      url('/fonts/NetflixSans_W_Lt.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
  }

  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  #__next {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - ${remCalc(60)});
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video,
  button {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    font-family: ${c.FONT_FAMILY_SANS_SERIF};
    line-height: 1.75;
    background: #ffffff;
    }
  }
  a:focus {
    outline: none;
  }

  ol,
  ul {
    list-style: none;
  }

  sup {
    font-size: 70%;
    position: relative;
    bottom: 1ex;
  }

  sub {
    font-size: 70%;
    position: relative;
    bottom: -1ex;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    text-decoration: none;
  }

  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: #e50a13;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 1031;

    width: 100%;
    height: 2px;
  }

  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    opacity: 1;
    transform: rotate(3deg) translate(0px, -4px);
  }

  ::-webkit-input-placeholder {
    color: #8c8c8c;
    font-weight: 300;
    font-family: ${c.FONT_FAMILY_SANS_SERIF};
  }
  ::-moz-placeholder {
    color: #8c8c8c;
    font-weight: 300;
    font-family: ${c.FONT_FAMILY_SANS_SERIF};
  }
  :-ms-input-placeholder {
    color: #8c8c8c;
    font-weight: 300;
    font-family: ${c.FONT_FAMILY_SANS_SERIF};
  }
  :-moz-placeholder {
    color: #8c8c8c;
    font-weight: 300;
    font-family: ${c.FONT_FAMILY_SANS_SERIF};
  }

  em {
    font-weight: 500;
  }
  a {
    color: #e50a13;
  }

  .no-scroll {
    overflow: hidden;
  }

  // TABLES
  table {
    border: 1px solid #e6e6e6;
  }

  thead {
    border: 1px solid #e6e6e6;
  }

  td {
    padding: 30px;
    border: 1px solid #e6e6e6;
  }

  td p {
    padding-bottom: 5px;
  }

  th {
    padding: 30px;
    text-align: left;
    font-weight: 500;
    border: 1px solid #e6e6e6;
  }
`;

export default Global;
