// @flow
import React from 'react';

import { Img } from './styles';

type Props = {
  src?: string,
  aspectRatio?: ?number,
  framed?: ?boolean,
  absolute?: ?boolean,
  fill?: ?boolean,
  alt?: ?string,
  onLoad?: ?() => void,
  style?: ?{},
  quality?: number,
};

const Image = ({
  src = '',
  absolute,
  alt,
  fill,
  onLoad,
  style,
  aspectRatio,
  quality = 80,
}: Props) => (
  <Img
    onLoad={onLoad}
    src={src}
    alt={alt}
    loaded={src}
    absolute={absolute}
    fill={fill}
    style={style}
    aspectRatio={aspectRatio}
    quality={quality}
  />
);

Image.defaultProps = {
  src: '',
  aspectRatio: 55.5,
  framed: false,
  absolute: false,
  alt: null,
  onLoad: null,
  fill: null,
  style: null,
  quality: 80,
};

export default Image;
