import styled, { css } from 'styled-components';

import { remCalc } from './styleMethods';
import * as c from './constants';
import * as s from './spacing';

export const PageWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 7vw;

  ${c.DESKTOP} {
    padding: 0;
  }
  ${({ centeredDesktop }) => css`
    margin: ${!centeredDesktop && 'initial'};
  `} ${({ horizontalLayout }) =>
    horizontalLayout &&
    css`
      ${c.NAV_BREAKPOINT_2} {
        display: flex;
        align-items: center;
        justify-content: space-between;
      },
    `};
`;

export const PageSection = styled.section`
  ${({ grouped }) =>
    grouped
      ? s.spacing({ margin: 'vertical-sm' })
      : s.spacing({ margin: 'vertical-lg' })};
  width: 100%;
`;

export const SectionText = styled.div`
  max-width: ${remCalc(650)};
`;
