import React from 'react';
// eslint-disable-next-line import/no-cycle
import ReactMarkdown from 'react-markdown';
import { BenefitsCostCalculatorContainer } from '../BenefitsCostCalculatorContainer';
import OptionsSelectors from '../OptionsSelector';
import { PageWrapper } from '../lib/pageSections';
import { formatUsd } from './formatUsd';
import { CalculatorContainer, Description, H3, H4, Header, TotalContainer } from './style';

export type HealthPlan = {
  title: string,
  employeeOnlyCost: number,
  employeeAndSpouseCost: number,
  employeeAndChildrenCost: number,
  employeeAndFamilyCost: number,
};

export type Props = {
  medicalPlans: ?Array<HealthPlan>,
  dentalPlans: ?Array<HealthPlan>,
  visionPlans: ?Array<HealthPlan>,
  maximumCashBack: number,
  costTitle: string,
  heading: string,
  description: string,
  benefitAllowance: number,
  familyTotalAllowance: number,
  waivedTotalAllowance: number,
  employeeOnlyTotalAllowance: number,
};

const BenefitsCostCalculator = ({
  heading,
  costTitle,
  dentalPlans,
  visionPlans,
  description,
  medicalPlans,
  maximumCashBack,
  benefitAllowance,
  familyTotalAllowance,
  waivedTotalAllowance,
  employeeOnlyTotalAllowance,
}: Props) => {
  return (
    <PageWrapper>
      <Header>{heading}</Header>
      <Description>
        <ReactMarkdown>{description}</ReactMarkdown>
      </Description>
      <CalculatorContainer>
        <BenefitsCostCalculatorContainer
          plans={{ medicalPlans, dentalPlans, visionPlans }}
          maximumCashBack={maximumCashBack}
          benefitAllowance={benefitAllowance}
          familyTotalAllowance={familyTotalAllowance}
          waivedTotalAllowance={waivedTotalAllowance}
          employeeOnlyTotalAllowance={employeeOnlyTotalAllowance}
        >
          {({
            medicalPlans: medicalSelections,
            dentalPlans: dentalSelections,
            visionPlans: visionSelections,
            total,
            cashBack,
            updatePlanSelection,
            totalAllowance,
          }) => (
            <React.Fragment>
              <OptionsSelectors
                plans={medicalPlans}
                type="medical"
                value={medicalSelections}
                updatePlanSelection={updatePlanSelection}
              />
              <OptionsSelectors
                plans={dentalPlans}
                type="dental"
                value={dentalSelections}
                updatePlanSelection={updatePlanSelection}
              />
              <OptionsSelectors
                plans={visionPlans}
                type="vision"
                value={visionSelections}
                updatePlanSelection={updatePlanSelection}
              />
              {totalAllowance && (
                <TotalContainer>
                  <H4>Annual Allowance based on your Medical Tier Enrollment</H4>
                  <H3 data-testid="total">{formatUsd(totalAllowance)}</H3>
                </TotalContainer>
              )}
              <TotalContainer>
                <H4>{costTitle}</H4>
                <H3 data-testid="total">{formatUsd(total)}</H3>
              </TotalContainer>
            </React.Fragment>
          )}
        </BenefitsCostCalculatorContainer>
      </CalculatorContainer>
    </PageWrapper>
  );
};

export default BenefitsCostCalculator;
