import styled from 'styled-components';

import { remCalc } from '../lib/styleMethods';
import * as c from '../lib/constants';
import * as g from '../lib/wrappers/Grid';

export const HeaderContainer = styled.header({
  top: 0,
  zIndex: 900,
  width: '100%',
  height: '80px',
  position: 'fixed',
  paddingTop: '15px',
  paddingBottom: '15px',
  background: c.COLORS.white,
  transition: `background 350ms ${c.DEFAULT_ANIMATION_TIMING}`,
});

export const Header = styled(g.Container)({
  zIndex: 2,
  width: '100%',
  display: 'flex',
  margin: '0 auto',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: remCalc(1320),

  [c.NAV_BREAKPOINT_2]: {
    paddingLeft: 64,
    justifyContent: 'space-between',
  },

  svg: {
    marginTop: 7,
  },
});
