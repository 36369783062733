export default {
  arrowRight:
    '<path d="M.274 8.48l1.452 1.375 4.615-4.87L1.73.11.274 1.488l3.312 3.496"/>',
  arrowRightLarge:
    '<path d="M.917 11.13l2.166 2.075L9.04 6.984 3.083.763.917 2.837l3.97 4.147z" />',
  arrowLeft:
    '<path fill="#FFF" d="M6.34 8.48L4.89 9.855.273 4.985 4.89.11l1.45 1.376-3.31 3.496"/>',
  arrowUp:
    '<path fill="none" stroke="#221F1F" stroke-width="3" d="M1 8.5L6.515 3 11 8.5"/>',
  arrowDown:
    '<path fill="#CFCFCF" d="M1.9361.627L.3731 2.2593 5.06 6.747l4.6868-4.4875L8.184.627 5.06 3.618z"/>',
  baselineChevronLeft:
    '<path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>',
  baselineChevronRight:
    '<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>',
  close:
    '<path fill="#CFCFCF" fill-rule="evenodd" d="M9.773 7.65l5.53-5.53C14.61 1.477 13.843.628 13.18 0L7.65 5.53 2.12 0C1.46.627.695 1.476 0 2.12l5.53 5.53L0 13.18c.604.696 1.427 1.456 2.12 2.123l5.53-5.53 5.53 5.53c.696-.667 1.518-1.427 2.123-2.122l-5.53-5.53z" />',
  closeBlack:
    '<path fill="#221F1F" fill-rule="evenodd" d="M9.773 7.65l5.53-5.53C14.61 1.477 13.843.628 13.18 0L7.65 5.53 2.12 0C1.46.627.695 1.476 0 2.12l5.53 5.53L0 13.18c.604.696 1.427 1.456 2.12 2.123l5.53-5.53 5.53 5.53c.696-.667 1.518-1.427 2.123-2.122l-5.53-5.53z"/>',
  facebook:
    '<path fill="#221F1F" fill-rule="evenodd" d="M17 34C7.61 34 0 26.39 0 17S7.61 0 17 0s17 7.61 17 17-7.61 17-17 17zm.894-11.002v-5.473h1.77l.264-2.133h-2.034v-1.36c0-.62.166-1.04 1.018-1.04H20v-1.908c-.188-.026-.834-.084-1.585-.084-1.568 0-2.64.994-2.64 2.82v1.572H14v2.133h1.774v5.473h2.12z" />',
  facebookSolid:
    '<path fill="#FFF" fill-rule="evenodd" d="M25.99 1.49V25.5c0 .822-.643 1.49-1.435 1.49h-6.623V16.537h3.38l.504-4.074h-3.884v-2.6c0-1.18.316-1.983 1.944-1.983h2.077V4.237c-.36-.05-1.592-.16-3.026-.16-2.995 0-5.045 1.897-5.045 5.384v3.005h-3.387v4.074h3.387v10.45H1.434C.642 26.99 0 26.322 0 25.5V1.49C0 .667.642 0 1.434 0h23.12c.793 0 1.436.667 1.436 1.49"/>',
  hamburger:
    '<path fill-rule="evenodd" d="M0 0h18v3H0V0zm0 6h18v3H0V6zm0 6h18v3H0v-3z"/>',
  trihamburger:
    '<g fill="#ADADAD" fill-rule="evenodd"><path d="M0 0h18v3H0zM3 5h12v3H3zM6 10h6v3H6z"/></g>',
  instagram:
    '<path fill="#221F1F" fill-rule="evenodd" d="M17 34C7.61 34 0 26.39 0 17S7.61 0 17 0s17 7.61 17 17-7.61 17-17 17zm.5-23c-1.494 0-1.68.007-2.268.034-.585.028-.985.123-1.335.263-.36.145-.668.34-.974.654-.306.32-.494.64-.634 1.01-.14.36-.23.77-.26 1.38s-.04.8-.04 2.34c0 1.54 0 1.73.03 2.34.02.606.12 1.018.25 1.378.14.37.324.688.63 1.003.306.31.613.506.974.65.35.14.75.236 1.334.264.585.03.772.035 2.266.035s1.68-.007 2.27-.035c.584-.03.984-.122 1.334-.262.36-.142.67-.34.976-.65.305-.317.493-.633.633-1.005.14-.36.23-.772.26-1.375.023-.605.03-.797.03-2.336 0-1.54-.008-1.73-.035-2.34-.028-.604-.12-1.015-.257-1.376-.14-.37-.328-.69-.634-1.003-.304-.315-.61-.51-.972-.654-.35-.14-.75-.236-1.332-.264-.59-.025-.775-.03-2.27-.03zm0 1.02c1.47 0 1.642.006 2.222.034.537.025.828.117 1.022.195.256.1.44.22.632.42.193.2.312.38.412.65.075.2.164.5.19 1.05.025.59.03.77.03 2.29s-.005 1.69-.03 2.29c-.026.55-.115.85-.19 1.05-.1.26-.22.45-.412.65-.192.2-.376.32-.632.42-.194.08-.485.17-1.022.19-.58.03-.753.03-2.222.03-1.47 0-1.643-.007-2.222-.035-.537-.025-.828-.117-1.022-.195-.256-.1-.44-.22-.632-.42-.193-.2-.312-.386-.412-.65-.075-.2-.164-.5-.19-1.053-.025-.596-.03-.775-.03-2.29 0-1.51.005-1.69.03-2.287.026-.553.115-.853.19-1.053.1-.26.22-.45.412-.65.192-.2.376-.32.632-.42.194-.08.485-.17 1.022-.2.58-.03.753-.03 2.222-.03zm-.136 1.5c-1.56 0-2.825 1.303-2.825 2.91 0 1.606 1.26 2.908 2.82 2.908s2.82-1.302 2.82-2.91c0-1.605-1.27-2.908-2.83-2.908zm0 4.798c-1.013 0-1.834-.846-1.834-1.89 0-1.04.82-1.887 1.834-1.887 1.012 0 1.833.85 1.833 1.89 0 1.05-.82 1.89-1.833 1.89zm3.574-4.964c0-.376-.296-.68-.66-.68-.365 0-.66.304-.66.68 0 .375.295.68.66.68.364 0 .66-.305.66-.68z"/>',
  instagramSolid:
    '<g fill="#FFF" fill-rule="evenodd"><path d="M12.996.045c-3.53 0-3.972.015-5.358.08C6.254.19 5.31.417 4.483.747c-.855.342-1.58.8-2.302 1.544-.72.75-1.16 1.5-1.49 2.38-.32.86-.54 1.83-.6 3.25C.016 9.34 0 9.8 0 13.44c0 3.63.015 4.09.078 5.512.063 1.424.283 2.397.604 3.25.332.88.777 1.626 1.5 2.37.722.744 1.446 1.202 2.3 1.544.828.33 1.772.557 3.156.622 1.386.065 1.828.08 5.358.08 3.53 0 3.972-.015 5.358-.08 1.383-.065 2.328-.29 3.155-.622.85-.342 1.57-.8 2.3-1.544.72-.744 1.16-1.49 1.5-2.37.32-.853.54-1.826.6-3.25.06-1.428.08-1.884.08-5.52 0-3.635-.02-4.09-.08-5.518-.07-1.425-.29-2.398-.61-3.25-.338-.88-.78-1.626-1.5-2.37-.727-.745-1.45-1.203-2.3-1.545C20.67.42 19.72.19 18.34.12c-1.388-.065-1.83-.08-5.36-.08m0 2.412c3.47 0 3.88.012 5.25.077 1.27.06 1.96.278 2.414.46.606.244 1.04.534 1.494 1.003.454.467.735.913.97 1.54.18.47.39 1.18.45 2.484.06 1.41.074 1.834.074 5.41 0 3.573-.015 3.996-.078 5.41-.058 1.303-.27 2.01-.448 2.483-.24.626-.52 1.07-.975 1.54-.453.47-.886.76-1.493 1-.46.185-1.15.403-2.416.463-1.37.063-1.78.077-5.25.077-3.47 0-3.88-.014-5.252-.078-1.27-.06-1.955-.28-2.413-.46-.605-.245-1.038-.535-1.493-1.004-.453-.466-.734-.912-.97-1.54-.177-.47-.39-1.18-.447-2.482-.062-1.412-.075-1.835-.075-5.41 0-3.574.01-3.997.073-5.408.056-1.307.27-2.015.446-2.487.24-.623.52-1.07.975-1.54.456-.466.89-.756 1.495-1 .46-.182 1.15-.4 2.416-.46 1.37-.064 1.784-.08 5.254-.08"/><path d="M12.674 17.336c-2.393 0-4.332-1.998-4.332-4.462 0-2.464 1.94-4.462 4.332-4.462s4.332 1.998 4.332 4.462c0 2.464-1.94 4.462-4.332 4.462m0-11.336C8.988 6 6 9.077 6 12.874c0 3.796 2.988 6.874 6.674 6.874 3.685 0 6.673-3.078 6.673-6.874C19.347 9.077 16.36 6 12.674 6m8.446-.394c0 .887-.7 1.607-1.56 1.607-.862 0-1.56-.72-1.56-1.607S18.698 4 19.56 4c.86 0 1.56.72 1.56 1.606"/></g>',
  linkedinSolid:
    '<path fill="#FFF" fill-rule="evenodd" d="M23.277 23.008H19.23v-6.266c0-1.495-.03-3.417-2.106-3.417-2.108 0-2.43 1.628-2.43 3.31v6.372h-4.05V10.123h3.887v1.76h.06c.54-1.01 1.863-2.08 3.834-2.08 4.1 0 4.858 2.668 4.858 6.138v7.07zM6.077 8.36c-1.3 0-2.35-1.04-2.35-2.323 0-1.28 1.05-2.32 2.35-2.32 1.295 0 2.347 1.04 2.347 2.32 0 1.282-1.052 2.323-2.348 2.323v.002zm-2.03 14.65H8.1V10.123H4.05v12.885zM25.295 0H2.017C.903 0 0 .872 0 1.946v23.106C0 26.127.903 27 2.017 27h23.278c1.114 0 2.024-.873 2.024-1.948V1.945C27.32.872 26.41 0 25.29 0z"/>',
  linkOut:
    '<path fill="none" d="M0 0h24v24H0z"/><path d="M19 19H5V5h7V3H5a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/>',
  twitterSolid:
    '<path fill-rule="evenodd" d="M10.064 26.89c12.076 0 18.68-10.344 18.68-19.314 0-.294-.006-.586-.02-.877C30.007 5.74 31.12 4.543 32 3.182c-1.176.54-2.442.904-3.77 1.068 1.355-.84 2.396-2.17 2.887-3.754-1.27.778-2.674 1.343-4.17 1.648C25.75.824 24.045 0 22.157 0c-3.626 0-6.567 3.04-6.567 6.788 0 .533.057 1.05.17 1.548C10.303 8.052 5.465 5.35 2.227 1.243c-.564 1.003-.89 2.17-.89 3.412 0 2.356 1.16 4.435 2.923 5.65-1.077-.033-2.09-.34-2.974-.848v.086c0 3.288 2.262 6.033 5.266 6.656-.55.153-1.132.236-1.73.236-.424 0-.835-.043-1.235-.122.836 2.697 3.26 4.66 6.133 4.715-2.247 1.82-5.078 2.905-8.154 2.905-.53 0-1.052-.03-1.566-.094 2.906 1.926 6.356 3.05 10.064 3.05"/>',
  search:
    '<path d="M14.93 12l4.356 4.358c-.455.687-1.378 1.613-2.12 2.12l-4.205-4.202C11.6 15.356 9.877 16 8 16c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8c0 1.458-.39 2.824-1.07 4zM8 13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5z" />',
  searchlg:
    '<path fill="#231F1F" d="M24.762 21.83L32 29.19c-.774 1.19-1.504 1.93-2.77 2.81l-7.158-7.28c-2.322 1.87-5.257 2.987-8.45 2.987C6.1 27.707 0 21.504 0 13.853 0 6.203 6.1 0 13.623 0c7.524 0 13.624 6.202 13.624 13.853 0 2.97-.92 5.723-2.485 7.978zm-11.14 1.544c5.167 0 9.356-4.263 9.356-9.522 0-5.26-4.19-9.522-9.356-9.522-5.167 0-9.355 4.263-9.355 9.522 0 5.26 4.188 9.522 9.355 9.522z"/>',
  twitter:
    '<path fill="#221F1F" fill-rule="evenodd" d="M17 34C7.61 34 0 26.39 0 17S7.61 0 17 0s17 7.61 17 17-7.61 17-17 17zm-2.226-11.916c4.528 0 7.005-3.88 7.005-7.243 0-.11-.005-.22-.01-.326.48-.36.9-.808 1.23-1.318-.44.202-.918.34-1.416.4.508-.314.9-.813 1.083-1.408-.477.292-1.004.504-1.565.618-.45-.494-1.09-.804-1.797-.804-1.36 0-2.462 1.14-2.462 2.547 0 .2.022.393.064.58-2.046-.107-3.86-1.12-5.075-2.66-.21.375-.333.813-.333 1.28 0 .882.433 1.662 1.094 2.12-.403-.015-.783-.13-1.115-.32v.032c0 1.232.85 2.26 1.974 2.495-.207.058-.424.09-.65.09-.158 0-.312-.017-.462-.047.314 1.012 1.222 1.748 2.3 1.768-.843.683-1.904 1.09-3.058 1.09-.198 0-.394-.012-.587-.035 1.09.724 2.383 1.145 3.774 1.145z"/>',
  team:
    '<path fill="#696868" fill-rule="evenodd" d="M14.09 8.41c.073.206.11.425.11.646 0 1.074-.87 1.944-1.943 1.944H5.772c-.292 0-.58-.066-.843-.192-.906-.434-1.32-1.475-.994-2.4h-2.77c-.17 0-.336-.036-.49-.106-.584-.27-.84-.962-.57-1.547.594-1.293 1.606-1.94 3.033-1.94 1.14 0 2.017.413 2.632 1.237.875-.62 1.952-.928 3.23-.928 1.286 0 2.37.313 3.252.94.61-.833 1.487-1.25 2.632-1.25 1.424 0 2.438.645 3.043 1.932.072.156.11.325.11.496 0 .645-.52 1.167-1.166 1.167H14.09zM9.023 5.044c-1.39 0-2.517-1.13-2.517-2.522S7.633 0 9.023 0s2.517 1.13 2.517 2.523c0 1.393-1.127 2.522-2.517 2.522zm-5.87-.278c-.84 0-1.52-.69-1.52-1.542 0-.853.68-1.543 1.52-1.543s1.52.69 1.52 1.545c0 .852-.68 1.542-1.52 1.542zm11.746 0c-.84 0-1.52-.69-1.52-1.542 0-.853.68-1.543 1.52-1.543s1.52.69 1.52 1.545c0 .852-.68 1.542-1.52 1.542z"/>',
  location:
    '<path fill="#696868" fill-rule="evenodd" d="M.314 7.58C.11 6.988 0 6.35 0 5.684 0 2.544 2.462 0 5.5 0S11 2.544 11 5.683c0 .665-.11 1.304-.314 1.898h.02l-.074.154c-.166.44-.383.856-.644 1.236-1.21 2.19-2.702 4.2-4.478 6.03-2.306-2.163-4.048-4.636-5.225-7.42h.03zm5.186.376c1.215 0 2.2-1.018 2.2-2.273 0-1.255-.985-2.273-2.2-2.273-1.215 0-2.2 1.018-2.2 2.273 0 1.255.985 2.273 2.2 2.273z"/>',
  play:
    '<svg width="60px" height="60px" viewBox="0 0 90 90"><path fill="#FFF" fill-rule="evenodd" d="M45 4C22.393 4 4 22.393 4 45s18.393 41 41 41 41-18.393 41-41S67.607 4 45 4m0 86C20.187 90 0 69.813 0 45S20.187 0 45 0s45 20.187 45 45-20.187 45-45 45M32 27.387v34.9c0 1.06 1.136 1.728 2.056 1.208l30.902-17.45c.938-.53.938-1.885 0-2.414L34.056 26.18c-.92-.52-2.056.148-2.056 1.207"/></svg>',
  x:
    '<path fill="#FFF" fill-rule="evenodd" d="M9.546 7.425L14.85 2.12 12.727 0 7.425 5.303 2.12 0 0 2.12l5.303 5.305L0 12.728l2.12 2.12 5.305-5.302 5.303 5.303 2.12-2.123-5.302-5.303z"/>',
  favorite:
    '<path fill="#ADADAD" d="M11.99 0C14.455 0 16 2.728 16 5.667c.057 4.27-5.7 10.44-8.043 10.332-.913-.047-2.9-1.378-4.494-3.015C1.353 10.815.035 8.315 0 5.682 0 2.474 1.773 0 4.427 0 5.952 0 7.172.523 8.02 1.536 8.936.516 10.284 0 11.99 0zM7.098 3.835C6.65 2.605 5.824 2.04 4.428 2.04c-1.45 0-2.494 1.456-2.494 3.627.026 1.993 1.1 4.033 2.878 5.86 1.276 1.312 2.933 2.42 3.23 2.434-.083 0-.006-.03.122-.092.22-.114.5-.292.815-.518.714-.52 1.512-1.226 2.24-2.005 1.79-1.92 2.866-3.944 2.843-5.66 0-1.975-.945-3.644-2.075-3.644-1.683 0-2.64.59-3.09 1.8-.32.87-1.487.868-1.804-.003z"/>',
  retweet:
    '<path fill="#ADADAD" d="M13.44 8.763V2.61l.02.198c-.14-.67-.457-.92-1.19-.92H7.447c-.514 0-.93-.423-.93-.944 0-.52.416-.944.93-.944h4.825c1.6 0 2.683.86 3.01 2.413l.02.197v6.182l1.11-1.126c.365-.37.954-.37 1.317 0 .362.368.362.966 0 1.335l-2.686 2.726c-.364.368-.953.368-1.317 0L11.14 9.1c-.364-.37-.364-.967 0-1.336.363-.368.953-.368 1.316 0l.986 1zM4.56 3.237V9.39l-.02-.198c.14.67.457.92 1.19.92h4.824c.514 0 .93.423.93.944 0 .52-.416.944-.93.944H5.73c-1.6 0-2.683-.86-3.01-2.413L2.7 9.39V3.208L1.59 4.334c-.365.37-.954.37-1.317 0C-.09 3.966-.09 3.368.273 3L2.957.275c.364-.368.953-.368 1.317 0L6.862 2.9c.364.37.364.967 0 1.336-.363.368-.953.368-1.316 0l-.986-1z"/>',
  thumbsup:
    '<path d="M4.205 7.145c1.257-.53 2.07-1.13 2.442-1.802.557-1.008 1.992-3.289 2.17-4.073.18-.784.963-.881 1.361-.881.399 0 1.292.38 1.292 1.155 0 .516-.347 2.083-1.042 4.701 1.277-.08 2.236-.12 2.877-.12.962 0 1.87.323 2.22.862.35.54.138 2.211 0 4.035-.138 1.823-.227 3.794-.529 4.21-.302.416-.759.997-1.69.997-.933 0-3.104.072-7.966-.992h-.863a1 1 0 0 1-1-1V7.983c0-.421.31-.779.728-.838zM1.5 7A1.5 1.5 0 0 1 3 8.5V14a1.5 1.5 0 0 1-3 0V8.5A1.5 1.5 0 0 1 1.5 7z" fill="#FFF" fillRule="evenodd"/>',
  thumbsdown:
    '<path d="M4.205 9.085c1.257.53 2.07 1.13 2.442 1.802.557 1.008 1.992 3.288 2.17 4.073.18.784.963.881 1.361.881.399 0 1.292-.38 1.292-1.155 0-.516-.347-2.083-1.042-4.701 1.277.08 2.236.119 2.877.119.962 0 1.87-.322 2.22-.861.35-.54.138-2.211 0-4.035-.138-1.823-.227-3.794-.529-4.21C14.694.582 14.237 0 13.306 0c-.933 0-3.104-.072-7.966.992h-.863a1 1 0 0 0-1 1v6.254c0 .421.31.779.728.838zM1.5 9.23A1.5 1.5 0 0 0 3 7.73v-5.5a1.5 1.5 0 0 0-3 0v5.5a1.5 1.5 0 0 0 1.5 1.5z" fill="#FFF" fillRule="evenodd" />',
  link:
    '<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g transform="translate(-432.000000, -181.000000)" fill="#9B9B9B"><path d="M442.36949,184.673746 L440.543368,186.500079 C440.047223,186.996013 439.369736,187.191548 438.72445,187.101937 L439.934732,185.891655 L441.760854,184.065321 C442.26505,183.561549 442.26505,182.743395 441.760854,182.238988 C441.256658,181.735003 440.438928,181.735003 439.934732,182.238988 L438.10861,184.065321 L436.897904,185.275815 C436.808293,184.630529 437.003616,183.95283 437.499762,183.456684 L439.325883,181.630775 C440.166281,180.789742 441.528881,180.789742 442.369278,181.630775 C443.209887,182.470748 443.209887,183.833772 442.36949,184.673746 L442.36949,184.673746 Z M438.717247,185.891655 L436.891125,187.717565 L436.282277,187.108928 L438.10861,185.282594 L438.717247,185.891655 L438.717247,185.891655 Z M433.238882,188.934838 C432.734686,189.439246 432.734686,190.256552 433.238882,190.760959 C433.743078,191.265579 434.560807,191.265579 435.065003,190.760959 L436.891125,188.934838 L438.101619,187.724344 C438.191442,188.369842 437.996119,189.047329 437.499974,189.543474 L435.673852,191.369384 C434.833455,192.210205 433.470854,192.210205 432.630457,191.369384 C431.789848,190.529411 431.789848,189.166387 432.630457,188.326413 L434.456579,186.500079 C434.952724,186.003722 435.630423,185.808399 436.275709,185.898222 L435.065215,187.108928 L433.238882,188.934838 L433.238882,188.934838 Z"/></g></g>',
  heart:
    '<path d="M0 6.42C-.033 10.895 9.382 17.978 10.338 18c.955.023 9.67-6.987 9.662-11.58C20 2.14 17.876 0 15.157 0c-1.813 0-3.42.89-4.82 2.673C9.186.89 7.635 0 5.688 0 2.763 0 0 2.587 0 6.42z" fill="#FFF" fill-rule="evenodd"/>',
  speechBubble:
    '<path d="M19.815 13.17c.616-1.185.96-2.505.96-3.897 0-5.12-4.65-9.273-10.388-9.273C4.65 0 0 4.152 0 9.273c0 5.12 4.65 9.272 10.387 9.272 2.078 0 4.014-.544 5.637-1.482L21 19l-1.185-5.83z" fill="#FFF" fill-rule="evenodd"/>',
  heartEmoji:
    '<svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg"><title>💓</title><text transform="translate(-652 -3626)" fill="#2A2727" fill-rule="evenodd" fontfamily="AppleColorEmoji, Apple Color Emoji" letter-spacing="-.1" font-size="12"><tspan x="652" y="3639">💓</tspan></text></svg>',
  playSmall:
    '<path fill="#FFF" fill-rule="evenodd" d="M20 1.778C9.952 1.778 1.778 9.952 1.778 20c0 10.048 8.174 18.222 18.222 18.222 10.048 0 18.222-8.174 18.222-18.222C38.222 9.952 30.048 1.778 20 1.778M20 40C8.972 40 0 31.028 0 20S8.972 0 20 0s20 8.972 20 20-8.972 20-20 20m-5.778-27.828v15.512c0 .47.505.767.914.536l13.734-7.756c.417-.235.417-.837 0-1.073l-13.734-7.753c-.41-.23-.914.065-.914.536"/>',
  check:
    '<path fill="#FFF" d="M12.44 18.38c-1.768-1.767-4.228-4.465-7.362-8.08L0 14.707c7.972 9.19 10.945 12.164 13.544 12.164 2.598 0 7.278-4.68 23.456-22.065L32.08.225c-6.488 6.97-11.47 12.193-14.934 15.66-1.524 1.522-2.746 2.7-3.654 3.513-.31-.288-.663-.628-1.052-1.018z"/>',
  caution:
    '<path d="M10.373.562l7.796 13.95A1 1 0 0 1 17.296 16H1.704a1 1 0 0 1-.873-1.488L8.627.562a1 1 0 0 1 1.746 0zM8 5v6h3V5H8zm0 7v2h3v-2H8z" fill="#E50914" fill-rule="evenodd"/>',
  youtubeSolid:
    '<path d="M18 0c.5 0-11.081 0-14 1-1.53.193-2.811 1.478-3 3-1 2.974-1 9-1 9s0 6.056 1 9c.189 1.522 1.439 2.777 3 3 2.95 1 14.5 1 14 1 .5 0 12.081 0 15-1a3.468 3.468 0 0 0 3-3c1-2.944 1-8.97 1-9 0 .03.03-6.026-1-9-.189-1.522-1.439-2.777-3-3-2.919-1-14.5-1-15-1zm-3 8l9 5.5-9 5.5V8z" fill="#FFF" fill-rule="nonzero" transform="scale(0.9)"/>',
  youtube:
    '<path d="M17 34C7.611 34 0 26.389 0 17S7.611 0 17 0s17 7.611 17 17-7.611 17-17 17zm-5.016-22.68a3 3 0 0 0-2.545 2.524 19.638 19.638 0 0 0-.204 2.912c0 1.113.078 2.18.234 3.203a3 3 0 0 0 2.551 2.52c1.538.214 3.184.321 4.937.321 1.83 0 3.631-.117 5.404-.351a3 3 0 0 0 2.574-2.53c.177-1.179.265-2.358.265-3.537 0-.887-.05-1.699-.15-2.436a3 3 0 0 0-2.577-2.571c-1.88-.25-3.76-.375-5.639-.375a34.57 34.57 0 0 0-4.85.32zm7.841 5.65L15.5 19.937V14l4.325 2.97z" fill="#221F1F" fill-rule="evenodd"/>',
  moon:
    '<path style="text-indent:0;text-transform:none;block-progression:tb" d="M38.375 9.004a2 2 0 0 0-.562.125C22.093 15.445 11 30.705 11 48.504c0 23.457 19.291 42.5 43 42.5a43.09 43.09 0 0 0 34.594-17.281 2 2 0 0 0-2.344-3.063 39.287 39.287 0 0 1-14.687 2.844c-21.574 0-39-17.232-39-38.5 0-8.551 2.819-16.423 7.593-22.813a2 2 0 0 0-1.78-3.187zm-5.219 7.031c-2.897 5.716-4.593 12.147-4.593 18.969 0 23.457 19.29 42.5 43 42.5 3.135 0 6.18-.397 9.125-1.031C73.71 82.968 64.364 87.003 54 87.003c-21.574 0-39-17.23-39-38.5 0-13.687 7.26-25.645 18.156-32.468z" overflow="visible" color="#000"/>',
  sun:
    '<path d="M245.8 138c29.8 0 56.7 12.1 76.2 31.6s31.6 46.5 31.6 76.2c0 29.8-12.1 56.7-31.6 76.2s-46.5 31.6-76.2 31.6c-29.8 0-56.7-12.1-76.2-31.6-19.6-19.5-31.6-46.5-31.6-76.2 0-29.8 12.1-56.7 31.6-76.2C189 150 216 138 245.8 138zm65.3 42.4c-16.7-16.7-39.8-27.1-65.4-27.1s-48.6 10.3-65.4 27.1c-16.7 16.7-27.1 39.8-27.1 65.4 0 25.5 10.3 48.6 27.1 65.4s39.8 27.1 65.4 27.1 48.6-10.3 65.4-27.1c16.7-16.7 27.1-39.8 27.1-65.4s-10.3-48.7-27.1-65.4zM69.1 238.1c-4.2 0-7.7 3.4-7.7 7.7 0 4.2 3.4 7.7 7.7 7.7h34.5c4.2 0 7.7-3.4 7.7-7.7s-3.4-7.7-7.7-7.7H69.1zM422.4 253.4c4.2 0 7.7-3.4 7.7-7.7 0-4.2-3.4-7.7-7.7-7.7h-34.5c-4.2 0-7.7 3.4-7.7 7.7s3.4 7.7 7.7 7.7h34.5zM135.2 124.3c-3-3-7.9-3-10.9 0s-3 7.9 0 10.9l20.6 20.6c3 3 7.9 3 10.9 0s3-7.9 0-10.9l-20.6-20.6zM356.4 367.2c3 3 7.9 3 10.9 0s3-7.9 0-10.9l-20.6-20.6c-3-3-7.9-3-10.9 0s-3 7.9 0 10.9l20.6 20.6zM253.4 70c0-4.2-3.4-7.7-7.7-7.7S238 65.7 238 70v34.5c0 4.2 3.4 7.7 7.7 7.7 4.2 0 7.7-3.4 7.7-7.7V70zM238.1 421.5c0 4.2 3.4 7.7 7.7 7.7 4.2 0 7.7-3.4 7.7-7.7V387c0-4.2-3.4-7.7-7.7-7.7s-7.7 3.4-7.7 7.7v34.5zM367.2 135.2c3-3 3-7.9 0-10.9s-7.9-3-10.9 0l-20.6 20.6c-3 3-3 7.9 0 10.9s7.9 3 10.9 0l20.6-20.6zM124.3 356.4c-3 3-3 7.9 0 10.9s7.9 3 10.9 0l20.6-20.6c3-3 3-7.9 0-10.9s-7.9-3-10.9 0c0-.1-20.6 20.6-20.6 20.6z"/>',
  linkedin:
    '<g fill="none" fill-rule="evenodd"><circle fill="#221F1F" cx="17" cy="17" r="17"/><path d="M21.345 21.226h-1.798V18.44c0-.665-.014-1.519-.936-1.519-.937 0-1.08.724-1.08 1.47v2.834h-1.8v-5.727h1.727v.782h.025c.24-.45.828-.925 1.704-.925 1.822 0 2.158 1.186 2.158 2.728v3.142zm-7.645-6.51a1.038 1.038 0 0 1-1.043-1.032c0-.57.466-1.032 1.043-1.032.576 0 1.044.463 1.044 1.032 0 .57-.468 1.032-1.044 1.032zm-.9 6.51v-5.727H14.6v5.727H12.8zM22.242 11H11.896c-.494 0-.896.388-.896.865v10.27c0 .477.402.865.896.865h10.346c.495 0 .9-.388.9-.866v-10.27c0-.476-.405-.864-.9-.864z" fill="#FFF" fillRule="nonzero"/></g>',
  podcast:
    '<g fill="none" fill-rule="evenodd"><circle fill="#221F1F" cx="17" cy="17" r="17"/><path d="M19.363 23.644h.135c1.35-.453 2.499-1.23 3.377-2.33 1.418-1.746 1.958-3.752 1.553-5.887-.338-1.747-1.283-3.17-2.701-4.335a7.36 7.36 0 0 0-3.917-1.553c-.878-.065-1.689-.065-2.5.13-1.755.387-3.173 1.293-4.254 2.652-.81 1.035-1.35 2.2-1.486 3.494-.135.841-.067 1.682.135 2.523.406 1.683 1.283 3.041 2.702 4.141.608.453 1.35.841 2.093 1.1.135.065.203 0 .203-.13 0-.129 0-.258-.068-.387-.067-.195 0-.389-.27-.453a6.122 6.122 0 0 1-2.634-1.941c-1.283-1.553-1.688-3.365-1.283-5.306.338-1.424 1.148-2.588 2.364-3.559 1.553-1.164 3.377-1.617 5.335-1.294 1.486.259 2.77.906 3.782 2.006 1.418 1.553 1.959 3.43 1.553 5.435-.405 2.07-1.62 3.559-3.579 4.464-.27.13-.473.26-.473.583 0 .259-.067.453-.067.647zm-4.12-1.359c.068.712.203 1.424.338 2.2.067.259.135.518.338.712a1.663 1.663 0 0 0 1.553.194c.54-.13.675-.518.743-.97.135-.712.202-1.489.337-2.2.135-.777.203-1.618.135-2.395a.933.933 0 0 0-.472-.84c-.743-.518-1.959-.518-2.702-.065-.337.194-.54.452-.54.84v.583l.27 1.941zm6.821-5.564c0-.194 0-.453-.067-.647-.203-1.294-.81-2.33-1.891-3.106-1.216-.906-2.567-1.23-4.12-.97-1.216.194-2.229.776-2.971 1.682-1.013 1.164-1.351 2.523-1.081 4.011.27 1.294.945 2.33 2.094 3.041.067 0 .135 0 .135.065 0-.065.067-.13.067-.194v-.647c0-.13-.067-.259-.135-.388-.81-.777-1.216-1.683-1.216-2.718 0-1.1.406-2.07 1.149-2.847.945-.906 2.16-1.294 3.511-1.164a3.988 3.988 0 0 1 2.567 1.358c.81.906 1.08 2.006.945 3.17a3.633 3.633 0 0 1-1.215 2.2.483.483 0 0 0-.136.26v.711c0 .065 0 .13.068.194.068 0 .135-.064.203-.064 1.35-.906 2.026-2.265 2.093-3.947zM17 14.52c-.878-.065-1.756.647-1.756 1.682 0 .97.81 1.682 1.756 1.682s1.756-.647 1.824-1.682c-.068-1.035-.946-1.682-1.824-1.682z" fill="#FFF" fill-rule="nonzero"/></g>',
};
