import React from "react";

const Logo = () => (
  <svg viewBox="0 0 94 36" xmlns="http://www.w3.org/2000/svg" height="54px">
    <g fill="none" fill-rule="evenodd">
      <path
        d="M61.912 0h-3.918v21.914c3.561.123 7.107.302 10.64.538V18.59a334.888 334.888 0 0 0-6.722-.38V0zm31.14.002H88.75L85.91 6.578 83.362.002h-4.24l4.556 11.75-4.973 11.529c1.376.134 2.748.277 4.119.428l2.892-6.702 2.865 7.388c1.492.192 2.98.392 4.466.604l.006-.002-5.105-13.165L93.053.002zm-48.509 21.72h3.919v-8.914h5.312V8.947h-5.312v-5.05h7.02V.003H44.543v21.72zM29.911 3.899h4.1v18.054c1.305-.05 2.611-.09 3.92-.124V3.898h4.1V.002h-12.12v3.896zM16.514 23.074c3.612-.328 7.24-.598 10.885-.808v-3.862c-2.329.135-4.651.294-6.966.476v-5.845c1.519-.015 3.52-.061 5.291-.04v-3.86c-1.415-.004-3.69.02-5.291.04V3.898h6.966V.002H16.514v23.072zm-7.686-8.926L3.724.002H0V25c1.304-.186 2.61-.363 3.919-.534V10.873l4.537 13.035a332.55 332.55 0 0 1 4.29-.47V.002H8.828v14.146zm63.036 8.536c1.308.1 2.614.208 3.918.324V.002h-3.918v22.682z"
        fill="#E50914"
      ></path>
    </g>
  </svg>
);

export default Logo;
