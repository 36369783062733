import React from "react";

export type HealthPlan = {
  title: string,
  employeeOnlyCost: number,
  employeeAndSpouseCost: number,
  employeeAndChildrenCost: number,
  employeeAndFamilyCost: number,
};

export type ContainerProps = {
  plans: {
    medicalPlans: ?Array<HealthPlan>,
    dentalPlans: ?Array<HealthPlan>,
    visionPlans: ?Array<HealthPlan>,
  },
  benefitAllowance: number,
  maximumCashBack: number,
  employeeOnlyTotalAllowance: number,
  familyTotalAllowance: number,
  waivedTotalAllowance: number,
  children: any,
};

export class BenefitsCostCalculatorContainer extends React.PureComponent<ContainerProps> {
  state = {
    medicalPlans: {
      plan: "",
      coverage: "",
    },
    dentalPlans: {
      plan: "",
      coverage: "",
    },
    visionPlans: {
      plan: "",
      coverage: "",
    },
  };

  /**
   * Event handler for selects. Must be
   * @param type
   * @param update
   */
  updatePlanSelection = (type, _update) => {
    const update =
      _update.plan && _update.plan === "Waived"
        ? { ..._update, coverage: "Waived" }
        : _update;

    this.setState((state) => ({
      [`${type}Plans`]: {
        ...state[`${type}Plans`],
        ...update,
      },
    }));
  };

  getPlanValue = (plan, type) => {
    if (plan) {
      if (plan.options) {
        const _coverage = this.state[type].coverage;
        const option = plan.options.find((o) => o.type.title === _coverage);
        return option ? option.value : 0;
      }
      const { coverage } = this.state[type];
      return plan[coverage] || 0;
    }
    return 0;
  };

  getCashBack = (totalCost) => {
    const { plans, maximumCashBack } = this.props;
    const benefitAllowance = this.getBenefitAllowance();

    // if the total cost is more than the allowance there is no cashback
    if (totalCost > benefitAllowance) {
      return 0;
    }
    const difference = benefitAllowance - totalCost;

    // Verify cash back is less than the specified maximum
    const result = difference > maximumCashBack ? maximumCashBack : difference;

    // if medical insurance is waived the total cost should be subtracted again
    // This doesn't make since, but I confirmed this is the desired behavior
    const medicalPlan = plans.medicalPlans.find(
      (plan) => plan.title === this.state.medicalPlans.plan
    );

    const medicalCost = medicalPlan
      ? this.getPlanValue(medicalPlan, "medicalPlans")
      : 0;

    if (!medicalCost) {
      return result;
    }

    return result;
  };

  getTotalCost = () =>
    // loop through all insurance types provided by the props
    Object.entries(this.props.plans).reduce((acc, [type, plans]) => {
      // find the selected plan based on the value in state
      const plan = plans.find((p) => p.title === this.state[type].plan);

      const value = plan ? this.getPlanValue(plan, type) : 0;
      return acc + value;
    }, 0.0);

  getTotals = () => {
    const totalCost = this.getTotalCost();
    const benefitAllowance = this.getBenefitAllowance();
    const total =
      totalCost > benefitAllowance ? totalCost - benefitAllowance : 0;
    const cashBack = this.getCashBack(totalCost);
    return {
      total,
      cashBack,
    };
  };

  getBenefitAllowance = () => {
    const totalAllownace = this.getTotalAllownace();
    return (totalAllownace / 26).toFixed(2);
  };

  getTotalAllownace = () => {
    const {
      employeeOnlyTotalAllowance,
      familyTotalAllowance,
      waivedTotalAllowance,
    } = this.props;
    if (!this.state.medicalPlans.coverage) {
      return "";
    }
    // Todo: Update to use the key. (need to rework option selector logic)
    if (
      this.state.medicalPlans.coverage === "Waived" &&
      (this.state.dentalPlans.coverage !== "Waived" ||
        this.state.visionPlans.coverage !== "Waived")
    ) {
      return waivedTotalAllowance;
    } else if (
      this.state.medicalPlans.coverage === "Employee Only" ||
      this.state.medicalPlans.coverage === "Waived"
    ) {
      return employeeOnlyTotalAllowance;
    } else {
      return familyTotalAllowance;
    }
  };

  render() {
    return this.props.children({
      ...this.state,
      ...this.getTotals(),
      updatePlanSelection: this.updatePlanSelection,
      totalAllowance: this.getTotalAllownace(),
    });
  }
}
