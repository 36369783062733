import styled from 'styled-components';

import TypeScale from '../lib/type';
import { remCalc } from '../lib/styleMethods';
import * as c from '../lib/constants';
import { spacing } from '../lib/spacing';

export const BannerContainer = styled('header')(
  spacing({ padding: 'top-xxl bottom-md' }),
  {
    width: '100%',
    marginTop: '50px',
    position: 'relative',
    display: 'flex',
    paddingLeft: 0,
  },
);

export const ImageWrapper = styled.div(
  {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    background: '#221F1F',
    overflow: 'hidden',

    '& img': {
      width: '100%',
      position: 'absolute',
      height: '100%',
      maxWidth: '100%',
      top: 0,
      left: 0,
      visibility: 'hidden',

      [c.DESKTOP]: {
        visibility: 'inherit',
      },
    },
  },
  ({ workplace }) => ({
    '&:before': {
      content: '""',
      ...c.ABSOLUTE,
      background: c.COLORS.nfBlack,
      opacity: 0.4,
      zIndex: 1,
      overflow: 'hidden',
      visibility: !workplace ? 'hidden' : 'inherit',
    },
  }),
);

export const TextWrapper = styled.div`
  display: flex;
  width: 80%;
  margin: ${c.PAGE_WRAPPER};
  z-index: 1;
  position: relative;
  text-align: left;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 5px;

  ${c.TABLET} {
    margin-top: 30px;
  }

  ${c.DESKTOP} {
    max-width: ${remCalc(c.SECTION_MAX_WIDTH)};
    margin: 0 7vw;
  }

  ${c.DESKTOP_HD} {
    margin: 0 auto;
  }
`;

export const H1 = styled(TypeScale.H1)({
  maxWidth: remCalc(780),
  textShadow: '0 2px 4px rgba(0,0,0,0.50)',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  display: '-webkit-box',
  overflow: 'hidden',
  color: c.COLORS.white,
  lineHeight: '1.2',
  fontWeight: '500',
});
