import React, { useState } from 'react';
import { capitalize } from '../lib/strings';
// eslint-disable-next-line import/no-cycle
import { HealthPlan } from '../BenefitsCostCalculator';
import SelectComponent from '../Select';

import { PlanSelectContainer, PlanContainer } from './style';

type Props = {
  plans: ?Array<HealthPlan>,
  type: string,
  value: {
    plan: string,
    coverage: string,
  },
  updatePlanSelection: () => void,
};

const OptionsSelectors = ({
  plans,
  type,
  value,
  updatePlanSelection,
}: Props) => {
  const defaultOptions = {
    'Employee Only': 'employeeOnlyCost',
    'Employee + Spouse/Domestic Partner': 'employeeAndSpouseCost',
    'Employee + Family': 'employeeAndFamilyCost',
    Waived: 'waived',
  };

  const [options, setOptions] = useState(Object.keys(defaultOptions));
  const [isDefault, setDefault] = useState(true);

  const updateOptionList = (selectedPlan: string) => {
    const plan = plans.find(p => p.title === selectedPlan);
    if (plan && plan.options) {
      const newOptions = plan.options.map(o => o.type.title);
      setOptions(newOptions);
      setDefault(false);
      return;
    }
    setOptions(Object.keys(defaultOptions));
    setDefault(true);
  };

  const coverageValue =
    isDefault && value.coverage
      ? Object.entries(defaultOptions).find(
          ([, val]) =>
            value.coverage &&
            val &&
            (val === value.coverage ||
              val.toLowerCase() === value.coverage.toLowerCase()),
        )[0]
      : value.coverage;

  const planId = `${type}_plan_selector`;
  const coverageId = `${type}_coverage_selector`;

  return (
    <PlanContainer>
      <PlanSelectContainer>
        <SelectComponent
          label={`${capitalize(type)} Plan Name`}
          logKPI={() => {}}
          placeholder="Select a plan"
          updateState={p => {
            const plan = p[planId];
            updatePlanSelection(type, { plan });
            updateOptionList(plan);
          }}
          id={planId}
          values={{ [planId]: value.plan }}
          options={[...plans.map(({ title }) => title), 'Waived']}
          testid={`${type}_plan`}
        />
      </PlanSelectContainer>
      <PlanSelectContainer>
        <SelectComponent
          label={`${capitalize(type)} Plan Coverage Level`}
          logKPI={() => {}}
          placeholder="Select a coverage level"
          updateState={c =>
            updatePlanSelection(type, {
              coverage: isDefault ? defaultOptions[c] : c[coverageId],
            })
          }
          id={coverageId}
          values={{ [coverageId]: coverageValue }}
          options={options}
          disabled={!value.plan || value.plan === 'Waived'}
          testid={`${type}_coverage`}
        />
      </PlanSelectContainer>
    </PlanContainer>
  );
};

export default OptionsSelectors;
