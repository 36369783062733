// @flow
import React from 'react';
import Downshift from 'downshift';
import { remCalc } from '../lib/styleMethods';
import Dropdown from './dropdown';

import { Container, Label } from './style';

export type SelectProps = {
  options: Array<string>,
  id: string,
  placeholder: string,
  label?: string,
  values: Object,
  updateState?: Function,
  disabled: boolean,
  dark?: boolean,
};

type State = {
  button: HTMLButtonElement | null,
  selectedItems: Array<string>,
};

const pxTofloat = px => parseFloat(px.replace('px', ''));

class SelectComponent extends React.Component<SelectProps, State> {
  state = {
    button: null,
    selectedItems: this.props.values[this.props.id]
      ? [this.props.values[this.props.id]]
      : [],
  };

  static defaultProps = {
    label: '',
    updateState: () => {},
    dark: false,
    disabled: false,
  };

  truncate = (val: string) => {
    if (!this.state.button) return val;
    const displayArea = this.state.button.firstChild;
    // $FlowFixMe
    const totalWidth = displayArea.offsetWidth;
    const leftPadding = pxTofloat(
      window
        .getComputedStyle(displayArea, null)
        .getPropertyValue('padding-left'),
    );
    const rightPadding = pxTofloat(
      window
        .getComputedStyle(displayArea, null)
        .getPropertyValue('padding-right'),
    );

    const displayWidth = totalWidth - (leftPadding + rightPadding);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = `${remCalc(18)} "NetflixSans"`;

    let valRenderedSize = context.measureText(val).width;

    if (valRenderedSize < displayWidth) {
      return val;
    }
    let size = val.length - 1;

    while (size > 0) {
      const newVal = `${val.substring(0, size)}...`;
      valRenderedSize = context.measureText(newVal).width;

      if (valRenderedSize < displayWidth) {
        return newVal;
      }
      size -= 1;
    }
    return '';
  };

  stateReducer = (state: Object, changes: Object) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: false,
        };
      case '__autocomplete_click_button__':
        return changes;
      default:
        return changes;
    }
  };

  handleChange = (item: string) => {
    const content = item;
    const selectedItems = [item];

    this.setState({ selectedItems });
    if (this.props.updateState && this.props.id) {
      this.props.updateState({ [this.props.id]: content });
    }
  };

  onResize = () => {
    if (this.props.updateState && this.props.id in this.props.values) {
      this.props.updateState({
        [this.props.id]: this.props.values[this.props.id],
      });
    }
  };

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize.bind(this));
  }

  setButton = (button: HTMLButtonElement) => {
    if (this.state.button) return;

    this.setState({
      button,
    });
  };

  render() {
    const { selectedItems } = this.state;
    const { id, label, values, disabled, ...rest } = this.props;

    return (
      <Container>
        {/* $FlowFixMe */}
        <Dropdown
          selectedItem={values[id]}
          onChange={this.handleChange}
          stateReducer={this.stateReducer}
          truncate={this.truncate}
          selectedItems={selectedItems}
          scope={this}
          disabled={disabled}
          setButton={this.setButton}
          {...rest}
        />
        <Label htmlFor={id}>{label} </Label>
      </Container>
    );
  }
}

export default SelectComponent;
